<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="editedItem.FirmaRolleID"
        item-text="Description"
        item-value="Value"
        :items="FirmaRolleItems"
        label="Firma rolle"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.RegionID"
        item-text="Description"
        item-value="Value"
        :items="RegionItems"
        label="Region"
      ></v-autocomplete>
      <v-text-field
        v-model="editedItem.FirmaNavn"
        label="Firma"
        maxlength="100"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.OrgNr"
        label="Org. Nr."
        maxlength="20"
      ></v-text-field>
      <v-text-field
        v-model.number="editedItem.ParentFirmaID"
        label="Mor Firma"
        hide-details
        single-line
        type="number"
      ></v-text-field>
      <v-checkbox
        v-model="editedItem.ChickenKunde"
        label="Chicken kunde"
        maxlength="20"
      ></v-checkbox>
      <v-text-field
        v-model="editedItem.GateAddresse"
        label="Gate addresse"
        maxlength="20"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.PostAddresse"
        label="Post addresse"
        maxlength="20"
      ></v-text-field>
      <v-autocomplete
        v-model="editedItem.PostNummer"
        item-text="Description"
        item-value="Value"
        :items="PostNummerItems"
        label="Postnummer"
      ></v-autocomplete>
      <v-text-field
        v-model="editedItem.FirmaMail"
        label="Firma E-Post"
        maxlength="80"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.FakturaMail"
        label="Faktura E-Post"
        maxlength="80"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.Web"
        label="Web"
        maxlength="100"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";

export default {
  extends: KeyValue,
  name: "dlgFirma",
  components: {},

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  created() {
    this.GetFirmaRolleCode();
    this.GetPostNummerCode();
    this.GetRegionCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
